<template>
  <div>
    <TheNavbarHorizontal></TheNavbarHorizontal>
  
    <div>
      <div>
        <vs-row vs-justify="center">
          <vs-row
            type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="4"
            vs-xs="12"
          >
            <div class="back-grewond2">
              <vs-row
                vs-justify="flex-center"
                vs-lg="12"
                vs-sm="4"
                vs-xs="12"
                style="padding: 10%"
              >
                <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                  </vs-col>
                  <vs-col
                    style="padding: 2%"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <img src="../assets/images/doclnia.png" />
                  </vs-col>
                </vs-row>
                <vs-row
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-md="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <h1 style="color: aliceblue">FAQ</h1>
                </vs-row>
              </vs-row>
            </div>
          </vs-row>
        </vs-row>
      </div>
    </div>
<vs-row vs-lg="6" vs-md="12" vs-sm="12"  vs-justify="space-between" style="padding: 5%;"
vs-align="center">
 
  <h1 >Featured articles</h1>
</vs-row>

<vs-row vs-lg="6" vs-md="12" vs-sm="12" vs-justify="center">
<vs-col vs-lg="8" vs-md="12" vs-sm="12">
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Can I book an online appointment or a surgery without being a
                  registered patient?
                </p>
              </div>
              You need to register yourself as a patient before booking any
              online appointment or surgery/treatment.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header"><p>How do I book a surgery?</p></div>
              In case you know the type of surgery to be performed, you need to
              go to hospitals, insert your specialty and surgery type you wish
              to book. In case you are not sure about the surgery type you need,
              please contact one of our patients service agents through
              patient.service@doclinia.com
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  How much does the treatment abroad cost if I take a companion
                  with me?
                </p>
              </div>
              Each country has its own policy when it comes to companion at the
              hospital. In case you wish to book accommodation and transfer
              arrangements for your companion, contact our travel consultants:
              travel@doclinia.com
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Is it possible to receive an additional discount in surgeries
                  and treatments?
                </p>
              </div>
              For humanitarian cases, please contact our patient service agents.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  How do I know which is the right surgery in my medical case?
                </p>
              </div>
              In case you have a local doctor’s medical report with the
              indicated surgery type, you can proceed to book. In case you are
              not sure about the right surgery or treatment, contact one of our
              consultants by email: patient.service@doclinia.com
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>Why should I put my medical history information?</p>
              </div>
              The medical history of the patient is a very important data for
              any doctor to evaluate the case, the medication prescribed and any
              danger at the time of a surgery evaluation.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>Can I contact the hospital directly?</p>
              </div>
              After booking and for any additional information, we recommend you
              to contact our patients service agents in order to avoid the
              language and protocol obstacles of the hospitals.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Why do I pay the processing fee for my surgery and is it
                  refundable?
                </p>
              </div>
              The processing fee is the fee paid for the organization and
              coordination with the hospital, doctors, needed translations of
              your documents as well as the confirmation of the desired date. In
              case the surgery is not accepted by the hospital or you wish to
              cancel the surgery, we refund you the amount paid deducting the
              paper work and organization fees. For more details you can consult
              our patient service agents department through
              patient.service@doclinia.com</vs-collapse-item
            >
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>Who will view my medical history, my tests & analyses?</p>
              </div>
              All your medical documents are treated according to the EU GDPR
              and will only be viewed by the doctors performing any medical
              consultation or treatment.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->

    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Does the online consultation with my surgeon have additional
                  charges?
                </p>
              </div>
              The booking of a surgery includes an online consultation with your
              surgeon free of charge.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Can I book an online appointment or surgery for my family
                  members?
                </p>
              </div>
              You can associate your family members to your profile as sub-users
              to request online appointments or surgeries/treatments for them.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>How do I pay the remaining amount of the surgery?</p>
              </div>
              Depending on the regulations of each country, the patient service
              agents will inform you the bank accounts of the booked hospital
              and the payment conditions related to your
              surgery.</vs-collapse-item
            >
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  Can I share the medical reports, tests and analysis with the
                  doctor without uploading them at the time of booking?
                </p>
              </div>
              The doctor will look into your medical reports, analysis, tests or
              any attached document before the time of the consultation to save
              time during the video-call. In case the doctor needs to revert
              back to any of those documents, he can access them through your
              medical history with him/her. Sharing the document will jeopardize
              the appropriate cycle of the evaluation and diagnosis of the
              doctor.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>What do I do to get an urgent visa?</p>
              </div>
              Our patient service agent will help you through an official letter
              from the hospital for the consulate of the country. Please check
              with our consultants all doubts regarding visa issuance.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>What guarantees do I have?</p>
              </div>
              The proposal of the hospital is an official document by the
              hospital for the price of the surgery and possible additional
              charges in case of complications. In case of doubts about the
              above, please contact our patients service
              agents.</vs-collapse-item
            >
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>Can I edit or cancel my online appointment?</p>
              </div>
              From the booking date to one week before your online consultation:
              The cancellation is free of charge From 6 days to 72 hours: 50% of
              the paid amount is refunded From 72h to consultation date:
              Non-refundable. In case you are changing the online appointment to
              another doctor, please refer to the patient service agent through
              patient.service@doclinia.com In case you are willing to cancel the
              online appointment, please check the cancellation conditions
              already illustrated at the time of booking or revert back to the
              patient service agent through patient.service@doclinia.com
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  How to know best hospital and destination for my medical case?
                </p>
              </div>
              There is a guide in our filter on the result page with our
              recommendation on the best destinations or hospitals for your
              surgery type. You are also guided by the rating of each hospital
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>How to order a package in Doclinia?</p>
              </div>
              Click on the package type Read carefully the details of the
              included and excluded items. Indicate the desired date and for how
              many persons. Submit your request through our system and a
              consultant will contact you for the coordination of your package
              booking.</vs-collapse-item
            >
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
    <div>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>Can I edit or cancel my online appointment?</p>
              </div>
              From the booking date to one week before your online consultation:
              The cancellation is free of charge From 6 days to 72 hours: 50% of
              the paid amount is refunded From 72h to consultation date:
              Non-refundable. In case you are changing the online appointment to
              another doctor, please refer to the patient service agent through
              patient.service@doclinia.com In case you are willing to cancel the
              online appointment, please check the cancellation conditions
              already illustrated at the time of booking or revert back to the
              patient service agent through patient.service@doclinia.com
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>
                  What should be taken into consideration when booking a surgery
                  or treatment?
                </p>
              </div>
              You need to make sure that you have a valid visa for the selected
              country. In case you need to issue a visa, please calculate one
              month time for your selected dates. In case of doubts about visa
              procedures, please contact our patient service agents by email.
            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-md="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-row
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
        >
          <vs-collapse open-hover accordion Margin>
            <vs-collapse-item Margin style="background: white">
              <div slot="header">
                <p>How do I book a follow up online appointment?</p>
              </div>
              Click on the package type During your online appointment, your
              doctor will evaluate the need of a follow up appointment and will
              inform the approximate date. The doctor will request from his
              access an online appointment for you and you will receive a
              notification on your profile to book the next online appointment
              with the same doctor You can go to “repeat online consultation”,
              choose the line of the consumed online appointment and it will
              take you to the calendar of the same doctor to book the follow-up
              appointment</vs-collapse-item
            >
          </vs-collapse>
        </vs-row>
      </vs-col>
    </div>
    <!--  -->
  </vs-col>
</vs-row>
    <Footer></Footer>

  </div>

</template>
<script>
// import TheNavbarHorizontal from "../layouts/components/navbar/TheNavbarHorizontal.vue";
import Footer from "../components/footer/Footer.vue";
export default {
  data: {},
  components: {
    // TheNavbarHorizontal,
    Footer,
    // HorizontalNavMenuHeader,
    // TheNavbarVerticalFullPage,
    // VerticalNavMenuGroup
  },
};
</script>

<style>
.vs-collapse-item--header {
  padding: 1rem;
}
.back-grewond2 {
  background-image: url("../assets/images/4.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
p:hover {
  color: rgb(85, 255, 0);
}
/* .desine{
    tex
}, */
/* a:hover {
  color: rgb(34, 255, 0);
} */
</style>
